var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-screen min-contents" },
    [
      _c("div", { staticClass: "container mx-auto" }, [
        _c("div", { staticClass: "w-full flex items-center justify-between" }, [
          _c("div", { staticClass: "flex items-center" }, [
            _vm.quote
              ? _c("h3", { staticClass: "mt-1.5 flex items-center" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "pages.commons.quotes." +
                            _vm.quoteState(_vm.quote, _vm.loginUserRole)
                        )
                      ) +
                      " "
                  ),
                  _c("img", {
                    staticClass: "w-8 h-8 mx-2 inline-block",
                    attrs: {
                      src: require("@/assets/image/purchased_icon.svg"),
                    },
                  }),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "text-right text-sm" }, [
            _vm.quote && "created_at" in _vm.quote
              ? _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("pages.commons.quotes.requested")) +
                      ": " +
                      _vm._s(_vm._f("date")(_vm.quote.created_at)) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm.quote
          ? _c(
              "div",
              { staticClass: "text-sm mt-2" },
              [
                _vm._v(" " + _vm._s(_vm.$t("pages.commons.quotes.id")) + ": "),
                _c("FlCopyableText", [_vm._v(_vm._s(_vm.quote.uuid))]),
              ],
              1
            )
          : _vm._e(),
        _vm.isImpersonated && _vm.impersonatedRole === "provider"
          ? _c("div", { staticClass: "w-full h-10 text-right" }, [
              _c(
                "button",
                {
                  staticClass:
                    "inline-block px-10 lg:px-6 md:px-4 py-2 bg-base-yellow font-bold",
                  on: {
                    click: function ($event) {
                      return _vm.impersonatedLogout(
                        "/admin/quotes/" + _vm.quote.uuid
                      )
                    },
                  },
                },
                [_vm._v(" 運用でquoteの詳細に戻る ")]
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "flex items-center mt-2 mb-8" }, [
          _vm.quote
            ? _c("div", { staticClass: "text-left" }, [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("pages.commons.quotes.ch_client_name")) +
                      " : "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "underline",
                      attrs: {
                        href: "/ch/vod_profile/" + this.quote.consumer.uuid,
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.quote.consumer.company_name) + " "
                      ),
                      _c(
                        "svg",
                        {
                          staticClass: "w-5 h-5 pb-1 inline",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            fill: "none",
                            viewBox: "0 0 24 24",
                            "stroke-width": "1.5",
                            stroke: "currentColor",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              d: "M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "flex items-center mb-4" }, [
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(_vm._s(_vm.$t("pages.commons.quotes.download_agreement"))),
          ]),
          _c(
            "button",
            {
              staticClass: "ont-bold py-2 px-4 action_btn ml-2",
              on: {
                click: function ($event) {
                  return _vm.downloadIndividualLicenseAgreement()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("commons.fields.download")) + " ")]
          ),
        ]),
        _vm.quoteMovies.length
          ? _c(
              "div",
              { staticClass: "clear-both" },
              _vm._l(_vm.quoteMovies, function (quoteMovie, quoteIndex) {
                return _c(
                  "div",
                  { key: quoteIndex, staticClass: "mb-2" },
                  [
                    _c(
                      "QuoteViewItem",
                      {
                        attrs: {
                          quoteItem: quoteMovie,
                          notDisplayItem: ["header-icon", "amount", "trash"],
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "border boder-t-0 p-4 text-sm" },
                          [
                            _c("TermsField", {
                              attrs: {
                                quoteItem: quoteMovie,
                                baseQuote: _vm.quote,
                                quoteType: "upload",
                                translatedMessage: _vm.translatedMessage,
                                translatedContractTerms:
                                  _vm.translatedContractTerms,
                              },
                            }),
                            (quoteMovie.status !== 8 &&
                              quoteMovie.status < 10) ||
                            _vm.isImpersonated === true
                              ? [
                                  quoteMovie.status === 5
                                    ? _c("div", [
                                        _c("p", [
                                          _vm._v(
                                            "下記の各項目より、納品素材のアップロードをお願いいたします。"
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            "メール等でGoogleドライブ等のURLをお送りいただいても対応できかねます。弊社にアップロードの代行を希望される場合には、有償サービスの「納品代行サービス」をお申し込みください。"
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            " すべての納品物のアップロードが完了しますと、一番したの「納品する」ボタンが黄色になり、クリックできるようになりますので、押していただくと、納品が完了いたします。 "
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            " なお、弊社にて確認の時間が必要になりますので、ご契約されているバイヤーへの納品日の5営業日前には、アップロードの完了をお願いいたします。 "
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            "アップロードするファイルのファイル名は半角英数字のみ対応しています。"
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            " また、TVシリーズ等の複数の動画ファイルや画像ファイルをアップロードする目的以外では、Zipファイルをお使いにならないでください。 "
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            "ai等の画像ファイルは、1ファイルでの納品になります。"
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            " TVシリーズのようにエピソードが複数ある場合、場面写真はエピソードの数だけ必要になります。ファイル名にエピソード番号を入れ（半角英数字のみ）、zipファイルにまとめてアップロードしてください。それによって、すべての納品が完了しているにも関わらず、納品ボタンが押せない場合などは、"
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "underline",
                                              attrs: {
                                                href: "https://ec.filmination.jp/contact",
                                              },
                                            },
                                            [_vm._v("お問い合わせ")]
                                          ),
                                          _vm._v("よりご連絡ください。"),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm.quote && _vm.loginUserRole === "consumer"
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-full rounded bg-light-grey mb-4 p-4",
                                          },
                                          [
                                            _c("p", { staticClass: "mb-1" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.complete_download_button_exp1"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("p", { staticClass: "mb-1" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.complete_download_button_exp2"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("p", { staticClass: "mb-1" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.complete_download_button_exp3"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("p", { staticClass: "mb-1" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.complete_download_button_exp4"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.requiredFilesArray[quoteIndex],
                                    function (file, fileIndex) {
                                      return [
                                        _c("FlDnDUploader", {
                                          key: "sub-vid-" + fileIndex,
                                          attrs: {
                                            filmName:
                                              quoteMovie.movie.film_name,
                                            fileStruct: file,
                                            qmUUID: quoteMovie.uuid,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.updateQuoteMovie(
                                                quoteIndex
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    }
                                  ),
                                  quoteMovie.screenshot_count &&
                                  quoteMovie.screenshot_format !== 1
                                    ? _c("FlDnDUploader", {
                                        attrs: {
                                          filmName: quoteMovie.movie.film_name,
                                          qmUUID: quoteMovie.uuid,
                                          outline: _vm.$t(
                                            "pages.commons.quotes.screenshot"
                                          ),
                                          description: _vm.$t(
                                            "pages.commons.quotes.image_upload"
                                          ),
                                          extensions: [
                                            _vm
                                              .textOfEnum(
                                                _vm.imageFormat,
                                                quoteMovie.screenshot_format
                                              )
                                              .toLowerCase(),
                                            "zip",
                                          ],
                                          fileCount:
                                            quoteMovie.screenshot_count,
                                          fileTypeNumber: 4,
                                          imageStructs: _vm.imageStructsForType(
                                            quoteIndex,
                                            4
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateQuoteMovie(
                                              quoteIndex
                                            )
                                          },
                                          add: function ($event) {
                                            return _vm.createImageStructOfType(
                                              quoteIndex,
                                              4,
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  quoteMovie.poster_visual_format &&
                                  quoteMovie.poster_visual_format !== 1
                                    ? _c("FlDnDUploader", {
                                        attrs: {
                                          filmName: quoteMovie.movie.film_name,
                                          qmUUID: quoteMovie.uuid,
                                          outline: _vm.$t(
                                            "pages.commons.quotes.poster"
                                          ),
                                          description: _vm.$t(
                                            "pages.commons.quotes.image_upload"
                                          ),
                                          extensions: [
                                            _vm
                                              .textOfEnum(
                                                _vm.imageFormat,
                                                quoteMovie.poster_visual_format
                                              )
                                              .toLowerCase(),
                                          ],
                                          fileTypeNumber: 2,
                                          imageStructs: _vm.imageStructsForType(
                                            quoteIndex,
                                            2
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateQuoteMovie(
                                              quoteIndex
                                            )
                                          },
                                          add: function ($event) {
                                            return _vm.createImageStructOfType(
                                              quoteIndex,
                                              2,
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  quoteMovie.logo_visual_format &&
                                  quoteMovie.logo_visual_format !== 1
                                    ? _c("FlDnDUploader", {
                                        attrs: {
                                          filmName: quoteMovie.movie.film_name,
                                          qmUUID: quoteMovie.uuid,
                                          outline: _vm.$t(
                                            "pages.commons.quotes.logo"
                                          ),
                                          description: _vm.$t(
                                            "pages.commons.quotes.image_upload"
                                          ),
                                          extensions: [
                                            _vm
                                              .textOfEnum(
                                                _vm.imageFormat,
                                                quoteMovie.logo_visual_format
                                              )
                                              .toLowerCase(),
                                          ],
                                          fileTypeNumber: 3,
                                          imageStructs: _vm.imageStructsForType(
                                            quoteIndex,
                                            3
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateQuoteMovie(
                                              quoteIndex
                                            )
                                          },
                                          add: function ($event) {
                                            return _vm.createImageStructOfType(
                                              quoteIndex,
                                              3,
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  quoteMovie.key_art_format &&
                                  quoteMovie.key_art_format !== 1
                                    ? _c("FlDnDUploader", {
                                        attrs: {
                                          fileName: quoteMovie.movie.film_name,
                                          qmUUID: quoteMovie.uuid,
                                          outline: _vm.$t(
                                            "commons.fields.key_art"
                                          ),
                                          description: _vm.$t(
                                            "pages.commons.quotes.image_upload"
                                          ),
                                          extensions: [
                                            _vm
                                              .textOfEnum(
                                                _vm.imageFormat,
                                                quoteMovie.key_art_format
                                              )
                                              .toLowerCase(),
                                            "zip",
                                          ],
                                          fileTypeNumber: 5,
                                          imageStructs: _vm.imageStructsForType(
                                            quoteIndex,
                                            5
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateQuoteMovie(
                                              quoteIndex
                                            )
                                          },
                                          add: function ($event) {
                                            return _vm.createImageStructOfType(
                                              quoteIndex,
                                              5,
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  quoteMovie.making_visual_format &&
                                  quoteMovie.making_visual_format !== 1
                                    ? _c("FlDnDUploader", {
                                        attrs: {
                                          fileName: quoteMovie.movie.film_name,
                                          qmUUID: quoteMovie.uuid,
                                          outline: _vm.$t(
                                            "commons.fields.making_stills"
                                          ),
                                          description: _vm.$t(
                                            "pages.commons.quotes.image_upload"
                                          ),
                                          extensions: [
                                            _vm
                                              .textOfEnum(
                                                _vm.imageFormat,
                                                quoteMovie.making_visual_format
                                              )
                                              .toLowerCase(),
                                          ],
                                          fileCount:
                                            quoteMovie.making_visual_count,
                                          fileTypeNumber: 6,
                                          imageStructs: _vm.imageStructsForType(
                                            quoteIndex,
                                            6
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateQuoteMovie(
                                              quoteIndex
                                            )
                                          },
                                          add: function ($event) {
                                            return _vm.createImageStructOfType(
                                              quoteIndex,
                                              6,
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  quoteMovie.laurels_format &&
                                  quoteMovie.laurels_format !== 1
                                    ? _c("FlDnDUploader", {
                                        attrs: {
                                          fileName: quoteMovie.movie.film_name,
                                          qmUUID: quoteMovie.uuid,
                                          outline: _vm.$t(
                                            "commons.fields.laurels"
                                          ),
                                          description: _vm.$t(
                                            "pages.commons.quotes.image_upload"
                                          ),
                                          extensions: [
                                            _vm
                                              .textOfEnum(
                                                _vm.imageFormat,
                                                quoteMovie.laurels_format
                                              )
                                              .toLowerCase(),
                                          ],
                                          fileTypeNumber: 7,
                                          imageStructs: _vm.imageStructsForType(
                                            quoteIndex,
                                            7
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateQuoteMovie(
                                              quoteIndex
                                            )
                                          },
                                          add: function ($event) {
                                            return _vm.createImageStructOfType(
                                              quoteIndex,
                                              7,
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  quoteMovie.banner_imagery_format &&
                                  quoteMovie.banner_imagery_format !== 1
                                    ? _c("FlDnDUploader", {
                                        attrs: {
                                          fileName: quoteMovie.movie.film_name,
                                          qmUUID: quoteMovie.uuid,
                                          outline: _vm.$t(
                                            "commons.fields.banner"
                                          ),
                                          description: _vm.$t(
                                            "pages.commons.quotes.image_upload"
                                          ),
                                          extensions: [
                                            _vm
                                              .textOfEnum(
                                                _vm.imageFormat,
                                                quoteMovie.banner_imagery_format
                                              )
                                              .toLowerCase(),
                                          ],
                                          fileTypeNumber: 8,
                                          imageStructs: _vm.imageStructsForType(
                                            quoteIndex,
                                            8
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateQuoteMovie(
                                              quoteIndex
                                            )
                                          },
                                          add: function ($event) {
                                            return _vm.createImageStructOfType(
                                              quoteIndex,
                                              8,
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _c("FlDnDUploader", {
                                    ref: "other",
                                    refInFor: true,
                                    attrs: {
                                      filmName: quoteMovie.movie.film_name,
                                      qmUUID: quoteMovie.uuid,
                                      outline: _vm.$t(
                                        "pages.commons.quotes.other_upload"
                                      ),
                                      extensions: [
                                        "pdf",
                                        "doc",
                                        "docx",
                                        "xls",
                                        "xlsx",
                                        "mov",
                                        "mp4",
                                        "jpg",
                                        "png",
                                        "tiff",
                                        "svg",
                                        "ai",
                                        "psd",
                                        "eps",
                                        "ott",
                                        "ttf",
                                        "srt",
                                        "csv",
                                        "srt",
                                        "txt",
                                        "wav",
                                        "zip",
                                      ],
                                      fileTypeNumber: 99,
                                      fileCount: -1,
                                      imageStructs: _vm.imageStructsForType(
                                        quoteIndex,
                                        99
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateQuoteMovie(quoteIndex)
                                      },
                                      add: function ($event) {
                                        return _vm.createImageStructOfType(
                                          quoteIndex,
                                          99,
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "p-4 text-sm grid grid-cols-4 bg-blue-200",
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("pages.commons.quotes.metadata")
                                    )
                                  ),
                                ]),
                                _c("div", { staticClass: "col-span-3" }, [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "pages.commons.quotes.metadata_guide"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      key: "movie-" + quoteIndex,
                                      staticClass:
                                        "font-black text-blue-600 underline cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.gotoMovie(
                                            _vm.quote.movies[quoteIndex].movie
                                              .uuid
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.domain) +
                                          "/movies/detail/" +
                                          _vm._s(
                                            _vm.quote.movies[quoteIndex].movie
                                              .uuid
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm.quote && _vm.loginUserRole === "provider" && _vm.isSpoofing
          ? _c(
              "div",
              { staticClass: "clear-both mx-auto w-4/12 my-10" },
              [
                _c(
                  "LinkButton",
                  {
                    attrs: {
                      disabled: _vm.quoteMovies[0].quote_provider.status !== 5,
                      classList: "px-2 py-3 mb-4",
                    },
                    on: {
                      clickButton: function ($event) {
                        return _vm.completeDelivery()
                      },
                    },
                  },
                  [
                    _vm.quoteMovies[0].quote_provider.status === 5
                      ? [
                          _vm._v(
                            "運用" +
                              _vm._s(
                                _vm.$t("pages.commons.quotes.deliver_files")
                              )
                          ),
                        ]
                      : [
                          _vm._v(
                            _vm._s(
                              _vm.$t("pages.commons.quotes.delivery_completed")
                            )
                          ),
                        ],
                  ],
                  2
                ),
              ],
              1
            )
          : _vm.quote && _vm.loginUserRole === "consumer"
          ? _c("div", [
              _c(
                "div",
                { staticClass: "grid grid-cols-12 gap-6" },
                [
                  _c(
                    "LinkButton",
                    {
                      staticClass: "col-start-5 col-span-4",
                      attrs: {
                        disabled: _vm.quote.status > 6,
                        classList: "px-2 py-3",
                      },
                      on: {
                        clickButton: function ($event) {
                          return _vm.completedDownload()
                        },
                      },
                    },
                    [
                      _vm.quote.status === 6
                        ? [
                            _vm._v(
                              _vm._s(
                                _vm.$t("pages.commons.quotes.received_files")
                              )
                            ),
                          ]
                        : [
                            _vm._v(
                              _vm._s(
                                _vm.$t("pages.commons.quotes.receive_complete")
                              )
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "p",
                {
                  staticClass:
                    "clear-both mx-auto w-1/2 md-10 pt-5 pb-10 text-justify text-xs font-bold",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "pages.commons.quotes.download_complete_button_explanation"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ])
          : _c("div", { staticClass: "clear-both mx-auto w-1/2 my-10" }, [
              _vm._m(0),
              _c(
                "button",
                {
                  staticClass:
                    "w-full h-full px-2 py-3 text-center bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker font-bold disabled:bg-very-light-grey disabled:cursor-not-allowed",
                  attrs: {
                    disabled: !(
                      _vm.checkUploadCompleted() &&
                      _vm.quoteMovies[0].quote_provider.status === 5 &&
                      !_vm.slackMessageSent
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.sendMessage()
                    },
                  },
                },
                [
                  _vm.slackMessageSent
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.$t("pages.commons.quotes.delivery_completed")
                          )
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(_vm.$t("pages.commons.quotes.deliver_files"))
                        ),
                      ],
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "clear-both mx-auto w/4/12 my-10 text-sm" },
                [
                  _vm._v(
                    " 素材のアップロードには、できるだけ、帯域制限のない光通信をご利用ください。ポケットWiFi（au スマートポート、DoCoMo home 5G、ソフトバンクAirを含む）、カフェなどのインターネットは推奨しておりません。 "
                  ),
                ]
              ),
            ]),
      ]),
      _c("NoticeModal", {
        attrs: {
          modalName: "successFailModal",
          contents: _vm.successFailMessage,
          buttons: ["OK"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg-light-grey rounded m-4 p-5" }, [
      _c("img", {
        staticClass: "w-5 h-5 inline",
        attrs: { src: require("@/assets/image/warning_icon2.svg") },
      }),
      _c("span", { staticClass: "font-semibold" }, [
        _vm._v(
          "『納品する』ボタンは1度だけ押してください。画面をリフレッシュすると、再びクリック可能になりますが、押さないでください。 弊社の依頼で新しい素材を追加したり、差し替えた場合には、再度、1度だけ押してください。 また、『納品する』ボタンを押しても、ステータスは、アップロード待ちのままになりますが、これは仕様になります。 理由は、弊社側で最低限の素材の確認を行うためです。弊社側での納品物の確認が終わりましたら、自動的に【ダウンロード待ち】に遷移いたしますので、そのままお待ちください。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }